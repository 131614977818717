<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>保证管理</el-breadcrumb-item>
			<el-breadcrumb-item>保证撤销列表</el-breadcrumb-item>
		</el-breadcrumb>
    <el-card>
			<!--查询-->
			<el-form ref="searchFormRef"
			         :model="searchForm"
			         label-width="100px"
			         class="search-form">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="票据包号：">
							<el-input v-model="searchForm.packageNo"></el-input>
						</el-form-item>
					</el-col>
					 <el-col :span="12">
						<el-form-item label="操作编号：">
							<el-input v-model="searchForm.operNo"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
          <el-col :span="12">
						<el-form-item label="申请日期：" class="special-form-item">
							<el-date-picker
											v-model="searchForm.applyDateStart"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
							<span class="data-gap">至</span>
							<el-date-picker
											v-model="searchForm.applyDateEnd"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-form-item class="btn-form-item">
						<el-button type="primary" @click="getTableList">查询</el-button>
						<el-button @click="handleReset">重置</el-button>
					</el-form-item>
				</el-row>
			</el-form>
			<!--列表-->
			<el-table :data="tableList" border>
				<el-table-column prop="operNo" label="操作编号" min-width="170px"></el-table-column>
        <el-table-column prop="ensureType" label="保证类型" min-width="100px"></el-table-column>
        <el-table-column prop="applyDate" label="申请日期" min-width="120px"></el-table-column>
        <el-table-column prop="packageNo" label="票据包号" min-width="150px"></el-table-column>
				<el-table-column prop="ticketRange" label="子票区间" min-width="130px"></el-table-column>
        <el-table-column prop="ensureAmt" label="保证金额(元)" min-width="150px"></el-table-column>
				<el-table-column prop="debtor" label="被保证人" min-width="180px"></el-table-column>
				<el-table-column prop="creditor" label="保证人" min-width="180px"></el-table-column>
				<el-table-column prop="" label="操作" fixed="right" min-width="300px">
					<template v-slot="scope">
						<el-button type="primary" size="mini" @click="handleFaceView(scope.row)">
							票面预览
						</el-button>
						<el-button type="primary" size="mini" @click="handleFaceDownload(scope.row)">
							票面下载
						</el-button>
						<el-button type="primary" size="mini" @click="handleGuatanteeRecall(scope.row)">
							保证撤销
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页区域-->
			<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="searchForm.pageNum"
							:page-sizes="[10, 20, 30, 40]"
							:page-size="searchForm.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="total"
							class="mt20">
			</el-pagination>
		</el-card>
  </div>
</template>

<script>
import queryBillVue from '../../billQuery/query/queryBill.vue'
export default {
  name: 'GuaranteeRecallList',
	mounted(){
		// 获取列表数据
		this.getTableList()
	},
  data(){
    return {
      // 搜索表单数据
      searchForm: {
        packageNo: '', //票据包号
        operNo: '', //操作编号
        applyDateStart: '', //申请日期 起始日期
        applyDateEnd: '',   //申请日期 结束日期
        pageSize: 10,  //当前页面数据量
        pageNum: 1,    //当前页码
      },
      total: 0, // 总数
      tableList: [], //列表数据
    }
  },
  methods: {
		// 获取列表数据
		async getTableList(){
			const {data: res} = await this.$auth.get('/cpiaoju-ticket/bz/mange/cancel/page',{params: this.searchForm})
			if(res.code !== 200) return this.$message.error(res.msg)
			this.total = res.data.total;
			this.tableList = res.data.records
		},
		// 保证撤销
		handleGuatanteeRecall(row){
			this.$router.push({
				name: 'guaranteeRecallDetail',
				query: {
					ticketBizId: row.ticketBizId
				}
			})
		},
    // 监听重置按钮
    handleReset(){
      this.searchForm.packageNo =  ''; //票据包号
      this.searchForm.operNo =  ''; // 操作编号
      this.searchForm.applyDateStart =  ''; //出票日期 起始日期
      this.searchForm.applyDateEnd =  '';   //出票日期 结束日期
			this.getTableList()
    },
    // 票面预览
    async handleFaceView(row){
      let params = {
        paperBagNo: row.packageNo,
        section: row.ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
      console.log(res)
      if (res.data.type === "application/pdf"){
        const blob = res.data;
        let link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = window.URL.createObjectURL(blob);
        link.click()
        URL.revokeObjectURL(link.href);
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
    //票面下载
    async handleFaceDownload(row){
      let params = {
        paperBagNo: row.packageNo,
        section: row.ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
      if (res.headers['content-disposition']){
        const blob = res.data;
        const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName
        console.log(link)
        link.click()
        URL.revokeObjectURL(url)
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
    // 监听pagesize改变
    handleSizeChange(newSize){
      this.searchForm.pageSize = newSize;
			this.getTableList()
    },
    // 监听pagenum改变
    handleCurrentChange(newPage){
      this.searchForm.pageNum = newPage;
			this.getTableList()
    },
  }
}
</script>

<style lang="less" scoped>
  .search-form{
		/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
			width: 100% !important;
		}
		.data-gap{
			display: inline-block;
			margin: 0 5px;
		}
		.special-form-item{
			/deep/ .el-form-item__content{
				display: flex;
			}
		}
		.el-select{
			width: 100%;
		}
		.btn-form-item{
			text-align: center;
			.el-button{
				width: 160px;
			}
		}
	}
</style>